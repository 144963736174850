<template>
  <div>
    <validation-observer ref="scholarship-observer">
      <div class="w-50">
        <b-form-group>
          <label for="i-scholarship-name" class="font-weight-bolder">
            {{ $t('Scholarship name') }} <required-field />
          </label>
          <validation-provider name="scholarshipName" rules="required|min:3|max:100" mode="passive" v-slot="{ errors }">
            <b-form-input id="i-scholarship-name" type="text" v-model="scholarshipName" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup
} from 'bootstrap-vue'
import { required, min, max } from '@mentoring-platform-validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    RequiredField,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    scholarship: {
      type: Object
    }
  },
  data() {
    return {
      scholarshipName: null
    }
  },
  created() {
    if(this.scholarship) {
      this.scholarshipName = this.scholarship.name
    }
  },
  methods: {
    isValid() {
      return this.$refs['scholarship-observer'].validate().then(success => success)
    },
    collectData() {
      return {
        name: this.scholarshipName
      }
    }
  }
}
</script>

<style scoped>

</style>
