import * as coreFilters from '@core/utils/filter'

const fixGreekCapitalLetters = (value) => {
	return value.replace(/Έ/g,'Ε')
				.replace(/Υ/g,'Υ')
				.replace(/Ί/g,'Ι')
				.replace(/Ό/g,'Ο')
				.replace(/Ά/g,'Α')
				.replace(/Ή/g,'Η')
				.replace(/Ώ/g,'Ω')
}

const currencySign = '€'

/**
 * Format price
 * @param {String} value date to format
 */
 export const price = (value) => {
  if (!value) return value
  if (value.indexOf(currencySign) != -1) return value
  return Math.round(value) + currencySign
}

export const upper = (value) => {
  if (!value) return ''
	value = value.toUpperCase()
	return fixGreekCapitalLetters(value)
}

export const formatGreekDate = (value, formatting = { month: 'short', year: 'numeric', day: '2-digit'  }) => {
	if (!value) return value
	if (!value.match(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)) return value
	return Intl.DateTimeFormat('el-gr', formatting).format(new Date(value))
  }

export default coreFilters 
