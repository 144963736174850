<template>
  <b-modal
    id="edit-scholarship-modal"
    ref="edit-scholarship-modal"
    centered
    size="lg"
    :title="$t('Edit scholarship')"
    title-class="font-weight-bolder"
    ok-only
    :ok-title="$t('Save')"
    @ok.prevent="editExistingScholarship"
  >
    <scholarship-item ref="scholarship-item" :scholarship="scholarship" />
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal
} from 'bootstrap-vue'
import { useScholarship } from '@mentoring-platform/composables'
import ScholarshipItem from '@mentoring-platform/views/components/blocks/Scholarships/ScholarshipItem.vue'

export default {
  components: {
    BButton,
    BModal,
    ScholarshipItem
  },
  data() {
    return {
      scholarship: null
    }
  },
  methods: {
    show(scholarship) {
      this.scholarship = scholarship
      this.$refs['edit-scholarship-modal'].show()
    },
    async editExistingScholarship() {
      const isValid = await this.$refs['scholarship-item'].isValid()
      if(isValid) {
        const payload = this.$refs['scholarship-item'].collectData()
        payload.scholarship_id = this.scholarship.id
        this.editScholarship(payload)
          .then(response => {
            this.$root.$bvToast.toast(this.$t('Data successfully saved'), {
              title: this.$t('Confirmation'),
              variant: 'success',
              solid: true,
            })
            this.$refs['edit-scholarship-modal'].hide()
            this.$bus.$emit('scholarships-list-needs-refresh')
          })
          .catch(error => {
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          })
      }
    }
  },
  setup() {
    const { editScholarship } = useScholarship()

    return {
      editScholarship
    }
  }
}
</script>

<style scoped>

</style>
