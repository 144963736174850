<template>
  <div>
    *
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
}
</script>

<style scoped>
div {
  display: inline-flex;
}
</style>
