<template>
  <div>
    <page-header-admin class="mb-2" :title="$t('Scholarships management')">
      <template #actions>
        <b-button
          variant="primary"
          class="btn-icon"
          @click="addNewScholarship"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t('Add new scholarship') }}
        </b-button>
      </template>
    </page-header-admin>
    <b-card>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Records') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <b-table
        :items="scholarships"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('There are not any scholarships')"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        no-provider-sorting
        no-provider-paging
        no-provider-filtering
        no-sort-reset
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #head()="{label}">
          {{ label | upper }}
        </template>

        <template #cell(created_at)="{item}">
          {{ item.created_at | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit'  }) }}
        </template>

        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body"
              />
            </template>
            <b-dropdown-item @click="editScholarship(item)">
              {{ $t('Change scholarship name') }}
            </b-dropdown-item>
            <!-- <b-dropdown-item>
              {{ $t('Delete scholarship') }}
            </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
    </b-card>
    <div>
      <b-row>
        <b-col
          class="d-flex justify-content-end"
        >
          <b-pagination
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            v-model="currentPage"
            first-number
            last-number
            :total-rows="totalRows"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <add-new-scholarship-modal ref="add-new-scholarship" />
    <edit-scholarship-modal ref="edit-scholarship" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'
import { useScholarship } from '@mentoring-platform/composables'
import AddNewScholarshipModal from '@mentoring-platform/views/components/blocks/Scholarships/AddNewScholarshipModal.vue'
import EditScholarshipModal from '@mentoring-platform/views/components/blocks/Scholarships/EditScholarshipModal.vue'
import vSelect from 'vue-select'

export default {
  components: {
    AddNewScholarshipModal,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BPagination,
    BRow,
    BTable,
    EditScholarshipModal,
    PageHeaderAdmin,
    vSelect
  },
  filters: {
    formatGreekDate,
    upper
  },
  data() {
    return {
      perPage: 50,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      searchQuery: null,
      sortBy: 'created_at',
      isSortDirDesc: true
    }
  },
  computed: {
    fields() {
      return [{
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'name',
        label: this.$t('Scholarship name'),
        sortable: true,
      },
      {
        key: 'no_of_subscriptions',
        label: this.$t('Mentees'),
        sortable: true
      },
      {
        key: 'created_at',
        label: this.$t('Creation date'),
        sortable: true
      },
      {
        key: 'actions',
        label: this.$t('Actions')
      }]
    },
    totalRows() {
      return this.scholarships.length
    }
  },
  methods: {
    addNewScholarship() {
      this.$refs['add-new-scholarship'].show()
    },
    editScholarship(scholarship) {
      this.$refs['edit-scholarship'].show(scholarship)
    }
  },
  beforeMount() {
    this.$bus.$on('scholarships-list-needs-refresh', this.getScholarships)
  },
  beforeDestroy() {
    this.$bus.$off('scholarships-list-needs-refresh', this.getScholarships)
  },
  setup() {
    const { scholarships, getScholarships  } = useScholarship()
    getScholarships()

    return {
      scholarships,
      getScholarships
    }
  }
}
</script>

<style scoped>

</style>
